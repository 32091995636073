
import { Link } from "react-router-dom";


const Joinnow = () => {
    return(
   <>
       <div class="carousel-caption">
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              <h5 class="text-white mt-3"> <span id="v2"> HELLO RESIDENT</span> </h5>
              <h3 class="text-white mt-4 text-uppercase"> <span id="v2"> WELCOME TO Vatika City</span></h3>
              <p class="text-white">  <span id="v2">All Rights Reserved For Vatika City Residents [Owners &amp; Tenants], <br />
              Offers and discounts are valid only for society residents. </span></p>
              <Link to="/login" class="btn custom-btn smoothscroll mb-5">JOIN US NOW!</Link>
            </div>
          </div>
        </div>
      </div>
   </>

    );
}

export default Joinnow;

import { Link } from "react-router-dom";


const Footer = () => {
    return(
   <>
        <footer className="site-footer"> 
          <div className="container mt-2">
            <div className="row">
              <div className="col-lg-7 col-12">
                <h3>
                  <div className="mt-4"> 
                    <img src="assets/images/logo1re.png" alt="" className="w-25 card" style={{backgroundColor: 'rgba(189, 186, 186, 0.692)' }}></img></div>
                </h3>
                <p className="mt-5">
                  <span className="text-white  mt-5" id="v2"> <i className="bi bi-geo-alt-fill" />
                  Block W, Uppal Southend, Sector 49, 
                  <br /> Gurugram, Haryana 122018.
                 
                  </span>
                </p>
              </div>
              <div className="col-lg-5 col-12 ">
                <div className="row">
                  <div className="col-lg-10 col-12 mt-5">
                    <h5 className="text-white" id="v2">
                    About Vatika City Communication Update
                    </h5>
                  </div>
                  <div className="col-lg-11 col-12 mt-5 mb-5">
                      <div class="input-group w-auto ">
                        <input
                          type="text"
                          class="form-control p-2"
                          placeholder="Your eamil address"
                          aria-label="Your eamil address"
                          aria-describedby="button-addon1"
                        />
                        <button data-mdb-button-init data-mdb-ripple-init class="btn btn-danger " type="button" id="button-addon1" data-mdb-ripple-color="dark">
                          Get Notified
                        </button>
                      </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container-fluid p-3 card  mt-5 " style={{ marginTop: '10px', backgroundColor: 'rgba(58, 56, 56, 0.5)', backfaceVisibility: '0.8' }}>
            <div className="row mt-1">
              <div className="col-lg-6 col-12 text-center">
                <Link to="#" style={{ color: 'rgb(247, 231, 2)', fontSize: '18px' }}> Vatika City , All
                  rights reserved.</Link>
              </div>
              <div className="col-lg-6 col-12 text-center">
                <a href="https://www.ratnkunj.com/" style={{ color: 'rgb(247, 231, 2)', fontSize: '18px' }} target="_blank" rel="noopener noreferrer">Managed by
                  Ratnkunj.com </a>
              </div>
            </div>
          </div>
        </footer>
   </>

    );
}

export default Footer;